.App {
  text-align: center;
}

.App-header {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #BFC3C6;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
